<template>
  <div>
    <search-form visible>
      <div class="d-inline-flex flex-wrap mb-3">
        <date-picker v-model="search.created.min" search-form :label="$t('shared.createdMin')" />
        <date-picker v-model="search.created.max" search-form :label="$t('shared.createdMax')" />
      </div>

      <div class="ma-1">
        <v-btn color="success" dark @click="getRecords" small :loading="progressing">
          <v-icon small class="me-1"> mdi-filter </v-icon>
          {{ $t('shared.applyFilter') }}
        </v-btn>
      </div>
    </search-form>

    <v-overlay absolute :value="progressing"> </v-overlay>

    <m-card :title="$t('health.bodyReport')">
      <LineChart :value="records" :title="$t('health.totalScore')" @point:click="getRecordItems" />
    </m-card>

    <v-card v-if="!$isEmpty(recordItems)" class="pa-6 mb-4">
      <v-row class="mb-8">
        <v-col v-for="item in recordItems" :key="item.id" cols="6" sm="4" md="3">
          <v-btn color="primary" block large :outlined="currentRecordItem.id !== item.id" @click="getBodyPartRecordItems(item)">
            {{ $t(`health.${item.bodyPart}`) }}
            <div class="ms-auto">
              <v-badge inline color="red" :content="item.redPointCount"> </v-badge>
              <v-badge inline color="yellow" :content="item.yellowPointCount"> </v-badge>
              <v-badge inline color="green" :content="item.greenPointCount"> </v-badge>
            </div>
          </v-btn>
        </v-col>
      </v-row>

      <h2 class="mb-3">{{ $t('health.totalNumberMoles') }} : {{ currentRecordItem.totalScore }}</h2>
      <h2 class="red--text mb-3">{{ $t('health.redPointCount') }} : {{ currentRecordItem.redPointCount }}</h2>
      <h2 class="yellow--text mb-3">{{ $t('health.yellowPointCount') }} : {{ currentRecordItem.yellowPointCount }}</h2>
      <h2 class="green--text mb-3">{{ $t('health.greenPointCount') }} : {{ currentRecordItem.greenPointCount }}</h2>
    </v-card>

    <m-card v-if="currentRecordItem.id" :title="$t(`health.${currentRecordItem.bodyPart}_Report`)">
      <LineChart :value="bodyPartRecords" :title="$t('health.totalScore')" @point:click="addCompareSection" />
    </m-card>

    <v-card v-if="!$isEmpty(compareSections)" class="pa-8 mb-4">
      <v-slide-group v-for="(section, index) in compareSections" :key="index" center-active show-arrows class="mb-8">
        <v-slide-item v-for="imageInfo in section.images" :key="imageInfo.id" v-slot="{toggle}">
          <div @click="toggle">
            <v-card :color="`${imageInfo.colour}`.toLowerCase()" class="me-2 my-2 pa-1" @click="section.selected = imageInfo">
              <Thumbnail
                v-if="imageInfo.image"
                class="rounded"
                :src="`${imageInfo.image}`"
                width="150"
                height="150"
              />
            </v-card>
          </div>
        </v-slide-item>
      </v-slide-group>

      <v-row v-if="!$isEmpty(compareSections)" class="mt-8">
        <v-col
          v-for="(section, index) in compareSections"
          :key="index"
          cols="12"
          sm="6"
          class="d-flex flex-column justify-center"
        >
          <template v-if="section.selected">
            <v-card :color="`${section.selected.colour}`.toLowerCase()" class="pa-1 mb-6 align-self-center">
              <v-btn
                color="grey"
                class="position-absolute"
                style="{top:5px, left:5px}"
                fab
                x-small
                overlap
                @click="removeSectionCompare(section)"
              >
                <v-icon color="white">mdi-close</v-icon>
              </v-btn>
              <Thumbnail :src="`${section.selected.image}`" width="250" height="250" class="rounded" />
            </v-card>
            <h2 class="align-self-center">{{ dateFormatter(section.selected.created) }}</h2>

            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">
                    {{ $t('health.size') }}: {{ section.selected.size }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider />

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">
                    {{ $t('health.texture') }}: {{ section.selected.texture }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider />

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">
                    {{ $t('health.color') }}: {{ section.selected.colour }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider />

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">
                    {{ $t('health.mel') }}: {{ section.selected.mel }}%
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider />

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">
                    {{ $t('health.bbc') }}: {{ section.selected.bbc }}%
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider />

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">
                    {{ $t('health.nv') }}: {{ section.selected.nv }}%
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider />
            </v-list>

            <div>
              <CInput v-model="section.selected.comment" textarea :label="$t('shared.comment')" />
              <v-btn color="primary" @click="saveComment(section.selected)"> {{ $t('shared.save') }} </v-btn>
            </div>
          </template>
        </v-col>

        <v-col v-if="compareSections.length < 2" cols="12" sm="6" class="d-flex flex-column align-center justify-start">
          <v-dialog v-model="dialog" width="500">
            <template v-slot:activator="{on, attrs}">
              <v-card
                class="d-flex flex-column align-center justify-center pa-1 mb-6"
                width="250"
                height="250"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon x-large> mdi-plus </v-icon>
              </v-card>
            </template>

            <v-card>
              <v-card-title class="text-h5 primary"> {{ $t('shared.chooseDate') }} </v-card-title>

              <v-list-item-group v-model="recordIndex" color="primary">
                <v-list-item v-for="(item, i) in bodyPartRecords" :key="i">
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold"> {{ dateFormatter(item.created) }} </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  :disabled="recordIndex === null"
                  :loading="progressing"
                  @click="addCompareSection(bodyPartRecords[recordIndex])"
                >
                  {{ $t('shared.choose') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import LineChart from './components/LineChart'
import moment from 'moment-jalaali'

export default {
  name: 'View',
  props: ['id'],
  components: {
    LineChart
  },
  data() {
    return {
      progressing: false,
      dialog: false,
      recordIndex: null,
      records: [],
      bodyPartRecords: [],
      recordItems: [],
      recordImages: [],
      currentRecordItem: {},
      compareSections: [],
      search: {
        userId: this.id,
        created: {
          min: null,
          max: null
        }
      }
    }
  },
  methods: {
    addCompareSection(item) {
      if (this.compareSections.length < 2) {
        this.getRecordImages(item)
      }
    },
    removeSectionCompare(item) {
      this.compareSections = this.compareSections.filter((i) => i.id !== item.id)
    },
    dateFormatter(date) {
      return moment.utc(date).format('YYYY/MM/DD')
    },
    async saveComment(item) {
      const {id, comment} = item
      const model = {comment}
      await this.$api.org.medicalImages.update(id, model)
      this.$showSuccess(this.$t('health.commentUpdated'))
    },
    async getRecordImages(item) {
      const filter = this.addSort({userId: this.id, medicalRecordDetailId: item.id})
      try {
        this.progressing = true
        const recordImages = await this.$api.org.health.medicalImageRecordDetails(filter)
        const compareSection = {
          id: Math.floor(Math.random() * 999999),
          images: recordImages,
          selected: recordImages[0] || null
        }
        this.compareSections.push(compareSection)
      } catch (err) {
        this.$showError(err)
      } finally {
        this.progressing = false
        this.dialog = false
      }
    },
    async getBodyPartRecordItems(item) {
      this.currentRecordItem = item

      let filter = this.$filter({
        userId: this.id,
        created: {
          min: this.search.created.min,
          max: this.search.created.max
        },
        bodyPart: item.bodyPart
      })
      filter = this.addSort(filter)

      try {
        this.progressing = true
        this.bodyPartRecords = await this.$api.org.health.medicalRecordDetails(filter)
      } catch (err) {
        this.$showError(err)
      } finally {
        this.progressing = false
      }
    },
    async getRecordItems(item) {
      const filter = this.addSort({userId: this.id, medicalRecordId: item.id})
      try {
        this.progressing = true
        this.recordItems = await this.$api.org.health.medicalRecordDetails(filter)
      } catch (err) {
        this.$showError(err)
      } finally {
        this.progressing = false
      }
    },
    async getRecords() {
      const filter = this.addSort(this.$filter())
      try {
        this.progressing = true
        this.records = await this.$api.org.health.medicalRecords(filter)
      } catch (err) {
        this.$showError(err)
      } finally {
        this.progressing = false
      }
    },
    selectImage(item) {
      if (this.selectedImages.length < 2) {
        this.selectedImages.push(item)
      } else {
        this.selectedImages = [this.selectedImages[0], item]
      }
    },
    addSort(filter) {
      return {
        ...filter,
        'sort[0].column': 'created',
        'sort[0].type': 'DESCENDING'
      }
    }
  }
}
</script>
